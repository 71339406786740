import React from 'react';
import {Container, LightContainer, Loader, SelectField, SelectfieldInput, TextfieldInput} from "../core/input_fields";
import Status from "../core/status";
import {apiGet, apiPost} from "../core/api";

import ReactTable from "react-table";
import {dateFormatTime} from "../filing/filing_support";
import {UserContext} from "../user/UserContext";
import {FormContext} from "../core/form_context";
import {FaTrashAlt} from "react-icons/fa";
import CreatableSelect from 'react-select/creatable';
import {getUsers} from "../core/core";
import branding from "../core/branding";


class CollaboratorsList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: "",
            loading: true,
            users: [],
            selectUsers: [],
            existingCollaborators: [],
            newCollaborator: "",
        };

        this.apiGet = apiGet.bind(this);
        this.apiPost = apiPost.bind(this);
        this.key = (this.props.match.params === undefined || this.props.match.params.key === undefined) ? this.props.key : this.props.match.params.key;

        this.setState = this.setState.bind(this);

    }

    componentDidMount() {
        this.loadUsers();
        getUsers(this.apiGet, this.setState)
    }

    loadUsers = () => {
        this.apiGet("/companies/permission/" + this.key, resp => {
            this.setState({
                users: resp,
                loading: false,
                error: "",
                existingCollaborators: resp.map(u => u.user.email)
            })

        });
    };
    static contextType = UserContext;
    handleChange = (e) => {
        if (e !== null && e.value) {
            this.setState({newCollaborator: e.value})
        }
    };
    addCollaborator = () => {
        this.setState({loading: true});
        this.apiPost("/companies/permission_add", {email: this.state.newCollaborator, Cmp_key: this.key}, resp => {
            this.loadUsers()
            this.setState({newCollaborator: ""})
        })
    };

    render() {
        const customStyles = {
            option: (provided, state) => ({
                ...provided,
            }),
            control: (provided) => ({
                ...provided,
                width: 400,
                padding: 0,
            }),

        };
        return (

            <LightContainer name={"Collaborators (except Admins)"} visible>
                <Status type={"error"} text={this.state.error}/>

                <Loader loading={this.state.loading}/>
                {
                    this.state.users.length > 0 &&
                    <ReactTable
                        data={this.state.users}
                        columns={[
                            {

                                columns: [
                                    {
                                        Header: "Name",
                                        accessor: "user.name",
                                        Cell: row => row.value.length < 3 ? row.original.user.email : row.value + (this.context?.user?.Role > 10 ? " (" + row.original.user.email + ")" : "")
                                        //Cell: row => <Popup on={"hover"} trigger={() => <>"lsjdf"</>}><>{row.value} ({row.original.user.email})</>
                                        //</Popup>
                                    },
                                    {
                                        Header: "Creation",
                                        accessor: "permission.creation",
                                        Cell: row => row.value > 0 ? dateFormatTime(row.value * 1000) : ""
                                        //Cell: row=>`${row.value} (${row.original.user.email})`
                                    },


                                    {
                                        id: "role",
                                        Header: "Role for Entity",
                                        maxWidth: 170,
                                        accessor: "permission.level",
                                        Cell: row => {
                                            //const user = row.original.user;
                                            //return role_name;
                                            if (this.context.user.Role >= 80) {
                                                return <CollaboratorLevelMod data={row.original}/>
                                            }
                                            return ["Read only", "Read and Write"][row.value] || "undefined";
                                        }
                                    },
                                    {
                                        Header: "Revoke",
                                        show: this.context.user.Role > 10,

                                        Cell: row => row.original.user.Role >= 80 ? "" : <FaTrashAlt style={{cursor: "pointer"}} onClick={() =>
                                            window.confirm("Do you want to revoke this permission?") && this.apiGet("/companies/permission_revoke/" + row.original.permission.ID, resp => {
                                                this.setState({users: this.state.users.filter(a => a.permission.ID !== row.original.permission.ID), loading: false});
                                            })
                                        }/>,
                                        maxWidth: 100
                                        //Cell: row=>`${row.value} (${row.original.user.email})`
                                    },


                                ]
                            }
                        ]}
                        pageSize={this.state.users.length}
                        className="-striped -highlight"
                        showPagination={false}
                        defaultSorted={[
                            {
                                id: "role",
                                desc: true
                            }
                        ]}
                    />
                }
                {
                    this.state.users.length === 0 &&
                    <div>No collaborators beside administrators found.</div>
                }
                <CreatableSelect
                    styles={customStyles}
                    options={this.state.selectUsers.filter(u => this.state.existingCollaborators.includes(u.email) === false && u.role < 80 && u.role > 30).map(u => ({value: u.email, label: u.name + (this.context?.user?.Role > 10 ? " (" + u.email + ")" : "")}))}
                    isSearchable={true}
                    isClearable={true}
                    value={this.state.newCollaborator !== "" && {value: this.state.newCollaborator, label: this.state.newCollaborator}}
                    onChange={this.handleChange}
                    //defaultValue={this.state.newCollaborator}
                    //value={this.state.newCollaborator}
                    className={"reactSelectContainer reactCreatableSelect"}
                    formatCreateLabel={inputValue => "Add User \"" + inputValue + "\""}
                    theme={(theme) => ({
                        ...theme,
                        borderRadius: 5,
                        colors: {
                            ...theme.colors,
                            primary25: branding.colorLight,
                            primary: branding.colorLight,
                            neutral20: branding.colorLight,
                            neutral30: branding.colorLight,

                        },
                    })
                    }
                />
                &nbsp;
                <button onClick={this.addCollaborator}>Add</button>
                {
                    this.context.user.Role >= 800 &&
                    <UserAdd loadUsers={this.loadUsers}/>
                }
                <br/><br/>

            </LightContainer>
        );
    }
}

class CollaboratorLevelMod extends React.Component {
    setState = this.setState.bind(this);
    apiPost = apiPost.bind(this)
    state = {role: this.props.data.permission.level};

    render() {
        const {user, permission} = this.props.data;
        return <FormContext.Provider value={{state: this.state, setState: this.setState}}>
            <form style={{marginTop: "-8px", marginBottom: "-10px"}} onChange={(e) => {
                const value = e.target.value;
                this.apiPost(`/companies/permission_mod`, {
                        Usr_ID: permission.Usr_ID,
                        permission_ID: permission.ID,
                        level: value,
                    },
                    () => {
                        this.setState({
                            role: value,
                        })
                    })
            }}
            >
                <SelectField name={""} tag={"role"} selectives={[
                    [1, "Read and Write"],
                    [0, "Read only"],
                ]}/>
            </form>
        </FormContext.Provider>
    }
}

class UserAdd extends React.Component {
    state = {
        name: "",
        email: "",
        role: 40,
        loading: false,
        success: ""
    };
    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    };
    apiPost = apiPost.bind(this);


    submit = e => {
        e.preventDefault();
        this.setState({loading: true, success: ""})
        this.apiPost("/user/add", this.state, resp => {
            this.setState({loading: false, success: resp.status, error: "", name: "", email: ""})
            this.props.loadFiles();
        })
    }

    render() {
        return <Container name={"Add New Permission"}>
            <form onChange={this.handleChange} onSubmit={this.submit}>
                <FormContext.Provider value={{
                    state: this.state, updateState: (name, child, value) => {
                        this.updateState({[name]: value})
                    }
                }}>
                    <Status type={"error"} text={this.state.error}/>
                    <Status type={"success"} text={this.state.success}/>

                    <TextfieldInput name={"Name"} tag={"name"} ph={"John Doe"}/><br/>
                    <TextfieldInput name={"Email"} tag={"email"} type={"email"} ph={"john.doe@vatomator.com"}/><br/>
                    <SelectField name={"Role"} tag={"role"} selectives={[
                        [10, "Client"],
                        [40, "Filer"],
                        [80, "Admin"],
                    ]}/>

                    <br/>
                    <button>
                        add
                    </button>
                    <Loader loading={this.state.loading}/>
                </FormContext.Provider>
            </form>
        </Container>


    }
}

export default CollaboratorsList;
