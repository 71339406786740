import React from "react"
import {UserContext} from "./user/UserContext";
import Popup from "reactjs-popup";
import {FaArrowCircleRight, FaDoorClosed, FaSignOutAlt} from "react-icons/fa";
import {Link, Route} from "react-router-dom";
import {apiGet} from "./core/api";
import CheckLogin from "./user/check_login";
import branding from "./core/branding";
import Consts from "./core/consts";

class Nav extends React.Component {
    state = {navOpen: false};
    static contextType = UserContext;
    apiGet = apiGet;


    render() {
        const BrandingLogo = branding.logo;
        return <>
            <header>
                <div style={{display: "none"}} id="config">
                </div>
                <i onClick={(e) => {
                    const newOpenBool = !this.state.navOpen
                    this.setState({"navOpen": newOpenBool})
                    document.body.setAttribute("class", (newOpenBool ? "" : "in") + "visible-nav")
                }
                } style={{float: "right"}} className="fa fa-bars"/>
                <h2 onClick={() => window.location.href = ""}><span className="uid color">VAT</span>omator.com</h2>
            </header>
            <nav>
                <div id="subnav">
                    <BrandingLogo/>
                    <div id="menu">

                        {
                            branding.domainName !== "demo.vatomator.com" && <>
                                <span className="uid color">VAT</span>omator&nbsp;&nbsp;&nbsp;&nbsp;
                                <i className="fa fa-circle"/>
                            </>
                        }
                        {
                            this.context.user.status === "logged_in" &&
                            <>
                                <CurrentLink to="/filings">Filings</CurrentLink>
                                <i className="fa fa-circle"/>



                                {
                                    this.context.user.Role >= 50 &&
                                    <>
                                        <CurrentLink to={[Consts.ENV_ESSENTIA, Consts.ENV_DEMO].includes(this.context.user.EnvID) ? "/groupcompanies" : "/companies"}>Companies</CurrentLink>
                                        <i className="fa fa-circle"/>
                                        {
                                            [Consts.ENV_ESSENTIA, Consts.ENV_DEMO].includes(this.context.user.EnvID) && <>
                                                <CurrentLink to="/companies">Countries</CurrentLink>
                                                <i className="fa fa-circle"/>
                                            </>
                                        }
                                        <CurrentLink to="/credentials">Credentials</CurrentLink>
                                        <i className="fa fa-circle"/>
                                    </>
                                }

                                {
                                    this.context.user.Role < 50 &&
                                    <>
                                        <CurrentLink to="/companies">Countries</CurrentLink>
                                        <i className="fa fa-circle"/>
                                    </>
                                }


                                {
                                    this.context.user.Role >= 30 &&
                                    <>

                                        <CurrentLink to="/users">Users</CurrentLink>
                                        <i className="fa fa-circle"/>
                                    </>
                                }

                                <Popup
                                    trigger={<a>Logged In</a>}
                                    position="bottom right"
                                    closeOnDocumentClick
                                    contentStyle={{overflow: "visible"}}
                                >
                                    <div style={{color: "#444", lineHeight: "30px"}}>
                                        Hi {
                                        this.context.user.Name
                                    }!<br/>
                                        <span style={{fontSize: "18px", lineHeight: "20px"}}>
                                            Your Role is {{4: "Filer", 8: "Administrator", 1: "client"}[Math.round(this.context.user.Role / 10)]} <br/>
                                            in {this.context.user.EnvName}! <br/>
                                        </span>
                                        <em style={{color: "#444", textDecoration: "underline"}} onClick={() => {
                                            this.setState({selectEnvActive: true})
                                        }}>change env <FaArrowCircleRight/></em> &nbsp;
                                        {
                                            this.state.selectEnvActive && <CheckLogin onClose={() => this.setState({selectEnvActive: false})} forceChange/>
                                        }
                                        <br/>
                                        <em onClick={() => {
                                            this.apiGet("/user/logout", () => {
                                                window.location.href = "/user/login"
                                            });
                                        }} style={{color: "#444", textDecoration: "underline"}}>log out <FaSignOutAlt/></em>

                                    </div>
                                </Popup>
                            </>
                        }
                        {
                            this.context.user.status !== "logged_in" &&
                            <>
                                <Link to="/user/login">Login</Link>
                            </>
                        }
                    </div>
                </div>
                <div id="footernav">
                </div>
            </nav>
        </>
    }
}

const CurrentLink = ({to, children}) => {
    return <Link to={to} className={
        to === window.location.pathname.split("/").slice(0, 2).join("/") || (to === "/filings" && (window.location.pathname === "/dashboard" || window.location.pathname === "/"))
            ? "current" : ""}>{children}</Link>
};
export default Nav;
