function dateFormatTime(inp) {
    if (inp > 0) {
        return new Intl.DateTimeFormat('en-GB', {
            year: '2-digit',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: "2-digit",
        }).format(inp);
    }
    return "unkown"
}

function dateFormat(inp) {
    if (inp > 0) {
        return new Intl.DateTimeFormat('en-GB', {
            year: 'numeric',
            month: 'short',
            day: '2-digit',
            //hour: 'numeric',
            //minute: '2-digit',
        }).format(inp);
    }
    return "unkown"

}


const GBPeriods_date = (str) => {
    if (!str || str.length === 0) {
        return ""
    }
    if (str === "#001") {
        return "initial period"
    }
    if (str.length <= 2) {
        return GBPeriods[str]
    }
    if (GBPeriods[str.substr(0, 2)] !== undefined) {
        return "20" + str.substr(2) + " - " + GBPeriods[str.substr(0, 2)];
    }
    if (GBPeriods[str.substr(2)] !== undefined) {
        return "20" + str.substr(0, 2) + " - " + GBPeriods[str.substr(2)];
    }
    return "";
    //return "20" + str.substr(0, 2) + " - " + GBPeriods[str.substr(2)]//dateFormatString(dates[0]) + " to " + dateFormatString(dates[1]);
}

function dateFormatString(date) {
    return new Intl.DateTimeFormat('en-GB', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
    }).format(Date.parse(date))
}

function extractSteuernummer(taxnr) {
    if (taxnr[0] === "5") {
        return taxnr.substr(1, 3) + "/" + taxnr.substr(5, 4) + "/" + taxnr.substr(9)
    } else if (taxnr[0] === "3" || taxnr[0] === "4" || taxnr[0] === "9") {
        return taxnr.substr(1, 3) + "/" + taxnr.substr(5, 3) + "/" + taxnr.substr(8)
    } else if (["21", "22", "23", "26", "24", "27", "28", "11"].indexOf(taxnr.substr(0, 2)) >= 0) {
        return taxnr.substr(2, 2) + "/" + taxnr.substr(5, 3) + "/" + taxnr.substr(8)
    }

    return taxnr
}

function formatReportName(name) {
    return name
        .split("_")
        .join(" ")
        .toUpperCase()
        .replace("RETURN", "Return")
        .replace("MANUAL", "Manual")
        .replace("INTRASTAT", "Intrastat")
        .replace("EC SL", "ECSL")
}


const GBPeriods = {
    "AA": "January",
    "AB": "February",
    "AC": "March",
    "AD": "April",
    "AE": "May",
    "AF": "June",
    "AG": "July",
    "AH": "August",
    "AI": "September",
    "AJ": "October",
    "AK": "November",
    "AL": "December",
    "A1": "Quarter I end. Apr",
    "A2": "Quarter II end. Jul",  // checked
    "A3": "Quarter III end. Oct",
    "A4": "Quarter IV end. Jan",
    "B1": "Quarter I end. May",
    "B2": "Quarter II end. Aug",  // checked
    "B3": "Quarter III end. Nov",
    "B4": "Quarter IV end. Feb",
    "C1": "Quarter I end. Mar",
    "C2": "Quarter II end. Jun", //checked
    "C3": "Quarter III end. Sep", //checked
    "C4": "Quarter IV end. Dec",

    "04": "Quarter I end. Apr",
    "07": "Quarter II end. Jul",
    "10": "Quarter III end. Oct",
    "01": "Quarter IV end. Jan",
    "05": "Quarter I end. May",
    "08": "Quarter II end. Aug",
    "11": "Quarter III end. Nov",
    "02": "Quarter IV end. Feb",
    "03": "Quarter I end. Mar",
    "06": "Quarter II end. Jun",
    "09": "Quarter III end. Sep",
    "12": "Quarter IV end. Dec",

    "YA": "Year start. Jan",
    "YB": "Year start. Feb",
    "YC": "Year start. Mar",
    "YD": "Year start. Apr",
    "YE": "Year start. May",
    "YF": "Year start. Jun",
    "YG": "Year start. Jul",
    "YH": "Year start. Aug",
    "YI": "Year start. Sep",
    "YJ": "Year start. Oct",
    "YK": "Year start. Nov",
    "YL": "Year start. Dec",

    "1": "Quarter I",
    "2": "Quarter II",
    "3": "Quarter III",
    "4": "Quarter IV",
};
const DEPeriods = {
    "01": "January",
    "02": "February",
    "03": "March",
    "04": "April",
    "05": "May",
    "06": "June",
    "07": "July",
    "08": "August",
    "09": "September",
    "10": "October",
    "11": "November",
    "12": "December",
    "41": "Quarter I",
    "42": "Quarter II",
    "43": "Quarter III",
    "44": "Quarter IV",
    "21": "January",
    "22": "February",
    "23": "March",
    "24": "April",
    "25": "May",
    "26": "June",
    "27": "July",
    "28": "August",
    "29": "September",
    "30": "October",
    "31": "November",
    "32": "December",
    "71": "2m - January-February",
    "72": "2m - February-March",
    "73": "2m - March-April",
    "74": "2m - April-May",
    "75": "2m - May-June",
    "76": "2m - June-July",
    "77": "2m - July-August",
    "78": "2m - August-September",
    "79": "2m - September-October",
    "80": "2m - October-November",
    "81": "2m - November-December",
    "82": "2m - December-January",

    "51": "January-April (4m)",
    "55": "May-August (4m)",
    "59": "September-December (4m)",
    "61": "January-June (6m)",
    "63": "6m - March-August",
    "67": "July-December (6m)",
    "69": "6m - September-February",
    "rtd": "Annual - RTD",
    "yf": "Annual",
    "yf2": "Annual (July to June)",
    /*"11": "Jan - Feb",
    "12": "Apr - Mai",*/
    "13": "Juli - Aug",
    "14": "Okt - Nov",
    "1": "Quarter I",
    "2": "Quarter II",
    "3": "Quarter III",
    "4": "Quarter IV",
    "5": "Year",
    "91": "Quarter I end. Mar",
    "92": "Quarter I end. Apr",
    "93": "Quarter I end. May",
    "94": "Quarter II end. Jun",
    "95": "Quarter II end. Jul",
    "96": "Quarter II end. Aug",
    "97": "Quarter III end. Sep",
    "98": "Quarter III end. Oct",
    "99": "Quarter III end. Nov",
    "100": "Quarter IV end. Dec",
    "101": "Quarter IV end. Jan",
    "102": "Quarter IV end. Feb",
    "111": "Year start. Jan",
    "112": "Year start. Feb",
    "113": "Year start. Mar",
    "114": "Year start. Apr",
    "115": "Year start. May",
    "116": "Year start. Jun",
    "117": "Year start. Jul",
    "118": "Year start. Aug",
    "119": "Year start. Sep",
    "120": "Year start. Oct",
    "121": "Year start. Nov",
    "122": "Year start. Dec",
    "prepay": "Pre Payment",
    "130": "Interim",

    "c1": "Claim 1",
    "c2": "Claim 2",
    "c3": "Claim 3",
    "c4": "Claim 4",
    "ch1": "Claim 1",
    "ch2": "Claim 2",
    "ch3": "Claim 3",
    "ch4": "Claim 4",


    "10101": "1 Jan",
    "10102": "1 Feb",
    "10103": "1 Mar",
    "10104": "1 Apr",
    "10105": "1 May",
    "10106": "1 Jun",
    "10107": "1 Jul",
    "10108": "1 Aug",
    "10109": "1 Sep",
    "10110": "1 Oct",
    "10111": "1 Nov",
    "10112": "1 Dec",
    "10201": "2 Jan",
    "10202": "2 Feb",
    "10203": "2 Mar",
    "10204": "2 Apr",
    "10205": "2 May",
    "10206": "2 Jun",
    "10207": "2 Jul",
    "10208": "2 Aug",
    "10209": "2 Sep",
    "10210": "2 Oct",
    "10211": "2 Nov",
    "10212": "2 Dec",
    "10301": "3 Jan",
    "10302": "3 Feb",
    "10303": "3 Mar",
    "10304": "3 Apr",
    "10305": "3 May",
    "10306": "3 Jun",
    "10307": "3 Jul",
    "10308": "3 Aug",
    "10309": "3 Sep",
    "10310": "3 Oct",
    "10311": "3 Nov",
    "10312": "3 Dec",
    "10401": "4 Jan",
    "10402": "4 Feb",
    "10403": "4 Mar",
    "10404": "4 Apr",
    "10405": "4 May",
    "10406": "4 Jun",
    "10407": "4 Jul",
    "10408": "4 Aug",
    "10409": "4 Sep",
    "10410": "4 Oct",
    "10411": "4 Nov",
    "10412": "4 Dec",
    "10501": "5 Jan",
    "10502": "5 Feb",
    "10503": "5 Mar",
    "10504": "5 Apr",
    "10505": "5 May",
    "10506": "5 Jun",
    "10507": "5 Jul",
    "10508": "5 Aug",
    "10509": "5 Sep",
    "10510": "5 Oct",
    "10511": "5 Nov",
    "10512": "5 Dec",
    "10601": "6 Jan",
    "10602": "6 Feb",
    "10603": "6 Mar",
    "10604": "6 Apr",
    "10605": "6 May",
    "10606": "6 Jun",
    "10607": "6 Jul",
    "10608": "6 Aug",
    "10609": "6 Sep",
    "10610": "6 Oct",
    "10611": "6 Nov",
    "10612": "6 Dec",
    "10701": "7 Jan",
    "10702": "7 Feb",
    "10703": "7 Mar",
    "10704": "7 Apr",
    "10705": "7 May",
    "10706": "7 Jun",
    "10707": "7 Jul",
    "10708": "7 Aug",
    "10709": "7 Sep",
    "10710": "7 Oct",
    "10711": "7 Nov",
    "10712": "7 Dec",
    "10801": "8 Jan",
    "10802": "8 Feb",
    "10803": "8 Mar",
    "10804": "8 Apr",
    "10805": "8 May",
    "10806": "8 Jun",
    "10807": "8 Jul",
    "10808": "8 Aug",
    "10809": "8 Sep",
    "10810": "8 Oct",
    "10811": "8 Nov",
    "10812": "8 Dec",
    "10901": "9 Jan",
    "10902": "9 Feb",
    "10903": "9 Mar",
    "10904": "9 Apr",
    "10905": "9 May",
    "10906": "9 Jun",
    "10907": "9 Jul",
    "10908": "9 Aug",
    "10909": "9 Sep",
    "10910": "9 Oct",
    "10911": "9 Nov",
    "10912": "9 Dec",
    "11001": "10 Jan",
    "11002": "10 Feb",
    "11003": "10 Mar",
    "11004": "10 Apr",
    "11005": "10 May",
    "11006": "10 Jun",
    "11007": "10 Jul",
    "11008": "10 Aug",
    "11009": "10 Sep",
    "11010": "10 Oct",
    "11011": "10 Nov",
    "11012": "10 Dec",
    "11101": "11 Jan",
    "11102": "11 Feb",
    "11103": "11 Mar",
    "11104": "11 Apr",
    "11105": "11 May",
    "11106": "11 Jun",
    "11107": "11 Jul",
    "11108": "11 Aug",
    "11109": "11 Sep",
    "11110": "11 Oct",
    "11111": "11 Nov",
    "11112": "11 Dec",
    "11201": "12 Jan",
    "11202": "12 Feb",
    "11203": "12 Mar",
    "11204": "12 Apr",
    "11205": "12 May",
    "11206": "12 Jun",
    "11207": "12 Jul",
    "11208": "12 Aug",
    "11209": "12 Sep",
    "11210": "12 Oct",
    "11211": "12 Nov",
    "11212": "12 Dec",
    "11301": "13 Jan",
    "11302": "13 Feb",
    "11303": "13 Mar",
    "11304": "13 Apr",
    "11305": "13 May",
    "11306": "13 Jun",
    "11307": "13 Jul",
    "11308": "13 Aug",
    "11309": "13 Sep",
    "11310": "13 Oct",
    "11311": "13 Nov",
    "11312": "13 Dec",
    "11401": "14 Jan",
    "11402": "14 Feb",
    "11403": "14 Mar",
    "11404": "14 Apr",
    "11405": "14 May",
    "11406": "14 Jun",
    "11407": "14 Jul",
    "11408": "14 Aug",
    "11409": "14 Sep",
    "11410": "14 Oct",
    "11411": "14 Nov",
    "11412": "14 Dec",
    "11501": "15 Jan",
    "11502": "15 Feb",
    "11503": "15 Mar",
    "11504": "15 Apr",
    "11505": "15 May",
    "11506": "15 Jun",
    "11507": "15 Jul",
    "11508": "15 Aug",
    "11509": "15 Sep",
    "11510": "15 Oct",
    "11511": "15 Nov",
    "11512": "15 Dec",
    "11601": "16 Jan",
    "11602": "16 Feb",
    "11603": "16 Mar",
    "11604": "16 Apr",
    "11605": "16 May",
    "11606": "16 Jun",
    "11607": "16 Jul",
    "11608": "16 Aug",
    "11609": "16 Sep",
    "11610": "16 Oct",
    "11611": "16 Nov",
    "11612": "16 Dec",
    "11701": "17 Jan",
    "11702": "17 Feb",
    "11703": "17 Mar",
    "11704": "17 Apr",
    "11705": "17 May",
    "11706": "17 Jun",
    "11707": "17 Jul",
    "11708": "17 Aug",
    "11709": "17 Sep",
    "11710": "17 Oct",
    "11711": "17 Nov",
    "11712": "17 Dec",
    "11801": "18 Jan",
    "11802": "18 Feb",
    "11803": "18 Mar",
    "11804": "18 Apr",
    "11805": "18 May",
    "11806": "18 Jun",
    "11807": "18 Jul",
    "11808": "18 Aug",
    "11809": "18 Sep",
    "11810": "18 Oct",
    "11811": "18 Nov",
    "11812": "18 Dec",
    "11901": "19 Jan",
    "11902": "19 Feb",
    "11903": "19 Mar",
    "11904": "19 Apr",
    "11905": "19 May",
    "11906": "19 Jun",
    "11907": "19 Jul",
    "11908": "19 Aug",
    "11909": "19 Sep",
    "11910": "19 Oct",
    "11911": "19 Nov",
    "11912": "19 Dec",
    "12001": "20 Jan",
    "12002": "20 Feb",
    "12003": "20 Mar",
    "12004": "20 Apr",
    "12005": "20 May",
    "12006": "20 Jun",
    "12007": "20 Jul",
    "12008": "20 Aug",
    "12009": "20 Sep",
    "12010": "20 Oct",
    "12011": "20 Nov",
    "12012": "20 Dec",
    "12101": "21 Jan",
    "12102": "21 Feb",
    "12103": "21 Mar",
    "12104": "21 Apr",
    "12105": "21 May",
    "12106": "21 Jun",
    "12107": "21 Jul",
    "12108": "21 Aug",
    "12109": "21 Sep",
    "12110": "21 Oct",
    "12111": "21 Nov",
    "12112": "21 Dec",
    "12201": "22 Jan",
    "12202": "22 Feb",
    "12203": "22 Mar",
    "12204": "22 Apr",
    "12205": "22 May",
    "12206": "22 Jun",
    "12207": "22 Jul",
    "12208": "22 Aug",
    "12209": "22 Sep",
    "12210": "22 Oct",
    "12211": "22 Nov",
    "12212": "22 Dec",
    "12301": "23 Jan",
    "12302": "23 Feb",
    "12303": "23 Mar",
    "12304": "23 Apr",
    "12305": "23 May",
    "12306": "23 Jun",
    "12307": "23 Jul",
    "12308": "23 Aug",
    "12309": "23 Sep",
    "12310": "23 Oct",
    "12311": "23 Nov",
    "12312": "23 Dec",
    "12401": "24 Jan",
    "12402": "24 Feb",
    "12403": "24 Mar",
    "12404": "24 Apr",
    "12405": "24 May",
    "12406": "24 Jun",
    "12407": "24 Jul",
    "12408": "24 Aug",
    "12409": "24 Sep",
    "12410": "24 Oct",
    "12411": "24 Nov",
    "12412": "24 Dec",
    "12501": "25 Jan",
    "12502": "25 Feb",
    "12503": "25 Mar",
    "12504": "25 Apr",
    "12505": "25 May",
    "12506": "25 Jun",
    "12507": "25 Jul",
    "12508": "25 Aug",
    "12509": "25 Sep",
    "12510": "25 Oct",
    "12511": "25 Nov",
    "12512": "25 Dec",
    "12601": "26 Jan",
    "12602": "26 Feb",
    "12603": "26 Mar",
    "12604": "26 Apr",
    "12605": "26 May",
    "12606": "26 Jun",
    "12607": "26 Jul",
    "12608": "26 Aug",
    "12609": "26 Sep",
    "12610": "26 Oct",
    "12611": "26 Nov",
    "12612": "26 Dec",
    "12701": "27 Jan",
    "12702": "27 Feb",
    "12703": "27 Mar",
    "12704": "27 Apr",
    "12705": "27 May",
    "12706": "27 Jun",
    "12707": "27 Jul",
    "12708": "27 Aug",
    "12709": "27 Sep",
    "12710": "27 Oct",
    "12711": "27 Nov",
    "12712": "27 Dec",
    "12801": "28 Jan",
    "12802": "28 Feb",
    "12803": "28 Mar",
    "12804": "28 Apr",
    "12805": "28 May",
    "12806": "28 Jun",
    "12807": "28 Jul",
    "12808": "28 Aug",
    "12809": "28 Sep",
    "12810": "28 Oct",
    "12811": "28 Nov",
    "12812": "28 Dec",
    "12901": "29 Jan",
    "12903": "29 Mar",
    "12904": "29 Apr",
    "12905": "29 May",
    "12906": "29 Jun",
    "12907": "29 Jul",
    "12908": "29 Aug",
    "12909": "29 Sep",
    "12910": "29 Oct",
    "12911": "29 Nov",
    "12912": "29 Dec",
    "13001": "30 Jan",
    "13003": "30 Mar",
    "13004": "30 Apr",
    "13005": "30 May",
    "13006": "30 Jun",
    "13007": "30 Jul",
    "13008": "30 Aug",
    "13009": "30 Sep",
    "13010": "30 Oct",
    "13011": "30 Nov",
    "13012": "30 Dec",
    "13101": "31 Jan",
    "13103": "31 Mar",
    "13105": "31 May",
    "13107": "31 Jul",
    "13108": "31 Aug",
    "13110": "31 Oct",
    "13112": "31 Dec"

};
const DEPeriodsShort = {
    "01": "Jan",
    "02": "Feb",
    "03": "Mar",
    "04": "Apr",
    "05": "May",
    "06": "Jun",
    "07": "Jul",
    "08": "Aug",
    "09": "Sep",
    "10": "Oct",
    "11": "Nov",
    "12": "Dec",
    "41": "I. Qu.",
    "42": "II. Qu.",
    "43": "III. Qu.",
    "44": "IV. Qu.",
    "21": "Jan",
    "22": "Feb",
    "23": "Mar",
    "24": "Apr",
    "25": "May",
    "26": "Jun",
    "27": "Jul",
    "28": "Aug",
    "29": "Sep",
    "30": "Oct",
    "31": "Nov",
    "32": "Dec",
    /*"11": "Jan - Feb",
    "12": "Apr - Mai",*/
    "13": "Juli - Aug",
    "14": "Okt - Nov",
    "1": "I. Qu.",
    "2": "II. Qu.",
    "3": "III. Qu.",
    "4": "IV. Qu.",
    "5": "Year",
};

function dateFromDay(year, day) {
    const tmp = new Date(year, 0, 1); // initialize a date in `year-01-01`
    const tmp2 = new Date(tmp.setDate(day))
    return tmp2;
}

export {
    dateFormatString, dateFormatTime, extractSteuernummer,
    GBPeriods_date, GBPeriods, DEPeriods, DEPeriodsShort,
    formatReportName, dateFormat,
    dateFromDay
}
