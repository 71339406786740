import React from 'react';
import {apiGet} from "../core/api";
import Status from "../core/status";
import {ListSubmittedItems, ListUnsubmittedItems} from "./filing_list_components";
import {InsertIfAdmin, Loader} from "../core/input_fields";

class FilingsByCompany extends React.Component {
    state = {
        filingExpanded: -10,
        loading: true,
        filings: [],
        showingKey: ""
    };
    load = () => {
        const {match, groupcompany_ID} = this.props;
        const key = match?.params?.key || this.props.key;

        if (this.state.showingKey !== key || groupcompany_ID) {
            window.setTimeout(() => this.setState({showingKey: key}), 100);
            this.apiGet("/vat_return/" + (groupcompany_ID ? "listbygroup/" + groupcompany_ID : "listbykey/" + key), resp => {
                this.setState({filings: resp.results, groupcompanies: resp.groupcompanies, loading: false, showingKey: key})
                if (resp.results.length > 0) {
                    this.props.addLatest(resp.results[0])
                }
            })
        }

    };
    componentDidMount = () => {
        this.load()
    };
    setExpanded = (id) => {
        this.setState({
            filingExpanded: id
        })
    };

    constructor(props) {
        super(props);
        this.apiGet = apiGet.bind(this);
        //this.load()
    }

    render() {
        const {params, groucompany_ID} = this.props;
        if (this.state.filings === undefined) {
            return null
        }
        const numbers = this.state.filings;


        const unsubmittedUnsorted = numbers.filter(d => !(d.data.general.report.indexOf("manual") > -1 && !!d.data.specific?.submitted) && d.status === 0);
        const submittedUnsorted = numbers.filter(d => (d.data.general.report.indexOf("manual") > -1 && !!d.data.specific?.submitted) || d.status !== 0);
        const unsubmitted = unsubmittedUnsorted.sort((a, b) => -a.data.lastSaved + b.data.lastSaved);
        const submitted = submittedUnsorted.sort((a, b) => {
            const timeA = a.submit_time === 0 ? ((((a.data || {}).specific || {}).submitTime || 0) !== 0 ? a.data.specific.submitTime : a.data.lastSaved) : a.submit_time;
            const timeB = b.submit_time === 0 ? ((((b.data || {}).specific || {}).submitTime || 0) !== 0 ? b.data.specific.submitTime : b.data.lastSaved) : b.submit_time;
            return -timeA + timeB
        });


        return <>
            {
                this.state.filings.length > 0 && <div>
                    <h1>Filings</h1>
                    <InsertIfAdmin>
                        {
                            this.state.groupcompanies?.length > 0 && <p>
                                See all registrations of {
                                this.state.groupcompanies.map(({ID, name}) => <><a href={"/groupcompanies/" + ID}>{name}</a> &nbsp;</>)
                            }
                            </p>
                        }
                    </InsertIfAdmin>
                    <Status type={"error"} text={this.state.error}/>
                    {(unsubmitted.length > 0 || this.state.loading) && <h2>Unsubmitted Filings</h2>}
                    <div id={"filings_list"} key={"unsubmitted"}>
                        <Loader loading={this.state.loading} div/>

                        <table style={{width: "100%"}}>

                            {
                                unsubmitted.length > 0 && unsubmitted.map((f) => <ListUnsubmittedItems filing={f} key={f.ID} t={this}/>)
                            }
                        </table>

                    </div>
                    <br/>
                    <h2>Submitted Filings</h2>
                    <div id={"filings_list"} key={"submitted"}>

                        <Loader loading={this.state.loading} div/>


                        <table style={{width: "100%"}}>

                            {
                                submitted.length > 0 && submitted.map((f) => <ListSubmittedItems filing={f} key={f.ID} t={this}/>)
                            }
                        </table>

                    </div>
                </div>
            }
        </>
    }
}


export default FilingsByCompany
