import React, {useState} from "react";
import Consts from "./consts";
import {dateFormatTime} from "../filing/filing_support";
import {FaTrashAlt} from "react-icons/fa";
import {FileUpload} from "./input_fields";
import {FileSelector} from "../filing/file_selection";
import Popup from "reactjs-popup";

export default function UploadMultipleFiles({label, files, scope, setStateParent, checkForAutosave, workbench}) {
    const [fileAddOpen, setFileAddOpen] = useState(false);
    return <div>
        <>
            {label}
            &nbsp; &nbsp; &nbsp;
            {
                !fileAddOpen && !!files && files.length > 0 &&
                <a onClick={() => setFileAddOpen(true)}>Add File</a>
            }
        </>
        {
            (files || []).map(file => {
                //is in form 852/1574088726-453886aa981629dc3d102ce88c8a969c587f7250.pdf
                const [usr_id, rest1] = file.path.split("/");
                const [time, rest2] = (rest1 || "").split("-");
                const [token, suffix] = (rest2 || "").split(".");
                return <li>
                    <a href={Consts.API_PREFIX + "/file/download/" + file.path + "?prettyName=" + (file.name || "")}>{file.name || suffix.toUpperCase() + " file"}</a>,&nbsp;
                    {dateFormatTime(time * 1000)}&nbsp;
                    <em onClick={() => {
                        if (window.confirm("Do you want to remove this file?")) {
                            setStateParent({[scope]: files.filter(fileFilter => fileFilter.path !== file.path)}, checkForAutosave)
                        }
                    }}><FaTrashAlt/></em>
                </li>
            })
        }
        {/*
                                    (!this.state.submissionFiles || this.state.submissionFiles.length === 0) &&
                                    <> <br/> No file added yet.</>
                                    */

        }
        {
            workbench && !(!fileAddOpen && !!files && files.length > 0) && <Popup className={"popup-modal"} modal
                                                                                             defaultOpen={fileAddOpen}
               trigger={<em>Add File</em>}
        >
            {
                close => <FileSelector tag={"newFile"} close={close} setStateParent={({fileTmp, fileTmpName}) => {
                    setStateParent({
                        [scope]: [...(files || []), {
                            path: fileTmp,
                            name: fileTmpName,
                        }]
                    }, checkForAutosave)
                }
                }/>
            }
        </Popup>
        }
        {
            !workbench &&!(!fileAddOpen && !!files && files.length > 0) &&

            <FileUpload
                name={"Add File"}
                tag={"submissionFileTmp"}
                setStateParent={setStateParent}
                onUpload={({file_name_orig, file_name}) => {
                    setStateParent({
                        [scope]: [...(files || []), {
                            path: file_name,
                            name: file_name_orig,
                        }]
                    }, checkForAutosave)
                }}
            />
        }
    </div>
}
