import React from "react";
import {FormContext} from "../core/form_context";
import Consts from "../core/consts";
import {dateFormatTime} from "./filing_support";
import {Container, FileUpload, LightContainer} from "../core/input_fields";
import Popup from "reactjs-popup";
import FilesPick from "../files/files_pick";
import {FaTrashAlt} from "react-icons/fa";

class FileSelection extends React.Component {
    constructor(props) {
        super(props);

        this.fieldName = props.tag === undefined ? "fileID" : props.tag;

        this.setState = this.setState.bind(this);
    }

    static contextType = FormContext;

    render() {
        const s = this.context === undefined || this.context.state === undefined ? this.props.state : this.context.state;
        return <>


            {s[this.fieldName] !== "" && s[this.fieldName] !== undefined &&
                <p style={{marginTop: "15px"}}>
                    <span style={{display: "inline-block", width: "150px"}}>File:</span>
                    <a
                        href={Consts.API_PREFIX + "/file/download/" + s[this.fieldName]}>
                        download
                    </a>
                    &nbsp;
                    (
                    {s[this.fieldName + "Name"] || s[this.fieldName].split(".")[1]}, &nbsp;
                    {
                        s[this.fieldName].length < 40 ? dateFormatTime(s[this.fieldName].split("-")[1].split(".")[0] * 1000) : dateFormatTime(s[this.fieldName].split("/")[1].split("-")[0] * 1000)
                    }
                    )
                    {
                        this.props.allowDelete &&       <em onClick={() => {
                            if (window.confirm("Do you want to remove this file?")) {
                                this.context.updateState(this.fieldName, "","");
                            }
                        }}><FaTrashAlt/></em>
                    }
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <Popup
                        className={"popup-modal"}
                        trigger={
                            <em onClick={() => {
                                //this.props.setStateParent({[this.fieldName]: ""});
                            }}>
                                new file
                            </em>
                        }
                        modal>
                        {close => <FileSelector tag={this.fieldName} close={close}/>}
                    </Popup>

                </p>
            }
            {
                (s[this.fieldName] === undefined || s[this.fieldName] === "") &&
                <>

                    <span style={{display: "inline-block", width: "155px"}}>File:</span>
                    <Popup className={"popup-modal"}
                           trigger={
                               <em className={"btn "} onClick={() => {
                                   this.props.setStateParent({[this.fieldName]: ""});
                               }}>
                                   Select File
                               </em>
                           }
                           modal>
                        {close => <FileSelector tag={this.fieldName} close={close}/>}
                    </Popup>

                </>
            }
            <br/>
        </>
    }
}

class FileSelector extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            method: "store"
        };
        this.fieldName = props.tag === undefined ? "fileID" : props.tag;

        this.setState = this.setState.bind(this);
    }

    static contextType = FormContext;
    setStateParent = ({fileTmp, fileTmpName}) => {
        this.context.updateState(this.fieldName, "", fileTmp);
        if (fileTmpName) {
            this.context.updateState(this.fieldName + "Name", "", fileTmpName);
        }
        this.props.close()
    };


    render() {
        const setStateParent = this.props.setStateParent || this.setStateParent;
        return <LightContainer name={"Select a File"}>

            <table style={{width: "100%"}} className={"table_tabs"}>
                <tr>
                    <td className={(this.state.method === "disk" ? "active" : "")} onClick={() => {
                        this.setState({method: "disk"})
                    }}>from Disk
                    </td>
                    <td className={(this.state.method === "store" ? "active" : "")} onClick={() => {
                        this.setState({method: "store"})
                    }}>from VATomator Workbench
                    </td>
                </tr>
            </table>
            {
                !this.context.state.Cmp_key && <>Please save the filing first.</>
            }
            {
                this.context.state.Cmp_key && <div>
                    {
                        this.state.method === "disk" &&
                        <>
                            <h1>Upload File</h1>
                            <FileUpload name={"File"} tag={"fileTmp"} storeInWorkbench={this.context.state.Cmp_key} setStateParent={setStateParent}/>
                        </>
                    }

                    {
                        this.state.method === "store" &&
                        <FilesPick tag={this.fieldName} onSelect={(file) => setStateParent({fileTmp: file.token, fileTmpName: file.name})}/>
                    }
                </div>
            }
        </LightContainer>
    }
}

export default FileSelection;
export {FileSelector};
